import React, {Component} from 'react'
import {Container} from "reactstrap"
import Camera from '../img/camera.svg'
import Bed from '../img/bed.svg'
import styled from 'styled-components'
import _ from 'lodash'
import config from '../config.json'
import VillaModal from "./VillaModal";
import {getNightRateStartingFrom} from "../util";


const CameraImg = styled.img`
  width: 14px;
  margin-right: 6px;
`

const BedImg = styled.img`
  width: 22px;
  margin-right: 5px;
`

class VillaSearch extends Component {
  state = {
    filters: {
      destination: 'All Destinations',
      villaType: 'All Types',
      location: 'All Locations',
      bedrooms: 0,
    },
    destinations: [
      {name: 'All Destinations'},
      {name: 'Koh Samui'},
      {name: 'Phuket'},
      {name: 'Krabi'},
      {name: 'Koh Phangan'},
    ],
    bedrooms: [
      {text: 'Bedrooms', value: ''},
      {text: '1+', value: '1'},
      {text: '2+', value: '2'},
      {text: '3+', value: '3'},
      {text: '4+', value: '4'},
      {text: '5+', value: '5'},
      {text: '6+', value: '6'},
      {text: '7+', value: '7'},
    ],
    locations: [
      'All Locations'
    ],
    villaTypes: [
      'All Villa Types',
      'Beachfront Villas',
      'Garden Pool View Villas',
      'Large Group Villas',
      'Resort Villas',
      'Sea View Villas',
      'Walk to the Beach',
    ],
    data: {list: []}
  }

  constructor(props) {
    super(props)
    this.villaModal = React.createRef()
  }

  componentDidMount() {
    fetch(`${config.apiUrl}`)
      .then(r => r.json())
      .then(j => {
        const {destinations} = j
        destinations.unshift({name: 'All Destinations'})
        this.setState({destinations}, this.reload)
      })

    // this.reload()
  }

  reload() {
    let url = `${config.apiUrl}/search`

    url += '?' + Object
      .entries(this.state.filters)
      .map(([key, val]) => {
        if (_.isBoolean(val)) {
          val = val ? '1' : '0'
        }
        if (_.isArray(val)) {
          val = val.join(',')
        }
        if (!_.isString(val)) {
          val = JSON.stringify(val)
        }

        return `sel${_.upperFirst(encodeURIComponent(key))}=${encodeURIComponent(val)}`
      })
      .join('&')

    fetch(url)
      .then(r => r.json())
      .then(d => this.setState({data: d}))
  }

  filter = e => {
    const {name, value} = e.target

    let {filters} = this.state

    if (name === 'destination') {
      let locations = _.find(this.state.destinations, {name: value}).locations || []
      locations.unshift('All Locations')
      this.setState({locations})
      _.extend(filters, {location: 'All Locations'})
    }

    _.extend(filters, {[name]: value})

    this.setState({filters}, this.reload)
  }

  onImageError = villa => e => {
    villa.images = _.reject(villa.images, i => i.image.toLowerCase() === e.target.src.toLowerCase())
    this.setState(this.state)
  }

  render() {
    const villas = this.state.data.list
    const count = this.state.data.count

    return (
      <div className="VillaSearch">
        <Container className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2>Find your Perfect Villa</h2>
              <span className="subheader">We have over 350 villas, search below to find the one for you.</span>
            </div>
          </div>
          <VillaModal villa={this.state.villa} ref={this.villaModal}/>
          {/*<p>Showing {villas.length}/{count} villas</p>*/}
          <div className="row" id="villaFilters">
            <div className="col">
              <div id="filtersWrapper">
                <div className="villaFilter">
                  <select name="destination" onChange={this.filter} value={this.state.filters.destination}>
                    {this.state.destinations.map((d, k) => (
                      <option key={k} value={d.name}>{d.name}</option>
                    ))}
                  </select>
                </div>
                <div className="villaFilter">
                  <select name="location" onChange={this.filter} value={this.state.filters.location}>
                    {this.state.locations.map((l, k) => (
                      <option key={k} value={l}>{l}</option>
                    ))}
                  </select>
                </div>
                <div className="villaFilter">
                  <select name="bedrooms" onChange={this.filter} value={this.state.filters.bedrooms}>
                    {this.state.bedrooms.map((b, k) => (
                      <option key={k} value={b.value}>{b.text}</option>
                    ))}
                  </select>
                </div>
                <div className="villaFilter">
                  <select name="villaType" onChange={this.filter} value={this.state.filters.villaType}>
                    {this.state.villaTypes.map((t, k) => (
                      <option key={k} value={t}>{t}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="villaListings">

            {villas.map((v, k) => (
              <div key={k} className="col-12 col-lg-6">
                <a className="villaListing aos-init aos-animate"
                   data-aos="fade-up"
                   href="#"
                   onClick={this.openVillaModal(v)}
                   data-toggle="modal"
                   data-target="#villaModal">
                  <img className="villaMainImg" onError={this.onImageError(v)} src={v.images[0].image}/>
                  <span className="villaPhotoCount"><CameraImg src={Camera} alt=""/>{v.images.length}</span>
                  <span className="villaPrice">From £{getNightRateStartingFrom(v)} / Night</span>
                  <span className="villaDetails">
                      <span className="villaDetailsLeft">
                        <strong className="villaName">{v.name}</strong>
                        <span className="villaLocale">
                          <strong className="villaDestination">{v.destination}</strong>
                          <strong className="villaLocation">{v.location}</strong>
                        </span>
                      </span>
                      <span className="villaDetailsRight">
                        <span className="villaBeds">
                          <BedImg src={Bed} alt=""/>
                          <span>{v.bedrooms}</span>
                        </span>
                      </span>
                    </span>
                </a>
              </div>
            ))}
          </div>
        </Container>
      </div>
    );
  }

  openVillaModal = villa => event => {
    event.preventDefault()
    this.setState({villa})
    this.villaModal.current.toggle();
  }
}

export default VillaSearch;
