import React from 'react'
import {Modal, ModalHeader, ModalBody, Container, Row, Col} from 'reactstrap';
import ImageGallery from "react-image-gallery";
import './VillaModal.scss';
import _ from 'lodash';
import styled from 'styled-components'
import {getNightRateStartingFrom} from "../util";
import ChatPerson from '../img/chat-person.svg'
import App from "../App";
import EnquiryButton from './EnquiryButton'

const ShortDescription = styled.p`
  padding: 20px 20px 10px;
  margin: 0;
`

const VillaPrice = styled.div`
  font-size: 1.4em;
  padding: 30px 35px 0;
`

const VillaIcons = styled.div`
  padding-bottom: 10px;

  p {
    margin-bottom: 0;
  }
  
  .col-md-3 {
    text-align: center;
    margin: 10px 0;
  }
`

const Location = styled.strong`
  font-size: 0.9rem;
  font-weight: 300;
  margin-left: 20px;

  > strong:nth-child(1) {
    &:after {
      content: '\\2022';
      margin: 0 5px;
    }
  }
`

const StyledEnquiryButton = styled(EnquiryButton)`
  margin-bottom: 20px;
`

class VillaModal extends React.Component {
  state = {
    modal: false
  }

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    const {villa} = this.props

    if (!this.props.villa) return null

    const images = villa.images.map(image => ({
      original: image.image,
      thumbnail: image.thumb,
    }))

    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="villa-modal">
          <ModalHeader toggle={this.toggle}>
            <span>{villa.name}</span>
            <Location>
              <strong className="villaDestination">{villa.destination}</strong>
              <strong className="villaLocation">{villa.location}</strong>
            </Location>
          </ModalHeader>
          <ModalBody>
            <ImageGallery
              items={images}
              showPlayButton={false}
              showFullscreenButton={false}
            />
            <VillaPrice>
              From £{getNightRateStartingFrom(villa)} / Night
            </VillaPrice>
            <Container>
              <Row>
                <Col sm={12}>
                  <ShortDescription>{_.unescape(villa.shortDescription)}</ShortDescription>
                </Col>
              </Row>
            </Container>
            <VillaIcons>
              <Container>
                <Row>
                  {villa.icons.map((icon, k) => (
                    <Col md="3" key={k}>
                      <p>{icon.type}</p>
                      <small>{icon.value}</small>
                    </Col>
                  ))}
                </Row>
              </Container>
            </VillaIcons>
            <StyledEnquiryButton />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default VillaModal