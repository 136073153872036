import React, {Component} from 'react';
import {Col, Container, Row} from "reactstrap";
import App from "../App";

class PrivacyPolicy extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="main">
          <Container>
            <Row>
              <Col>
                <h1>Privacy Policy</h1>
                <strong>
                  <span>1.<span> Who is responsible for managing my information?</span></span>
                </strong>
                <p><span
                >This is the Privacy Policy of Thailand Retreats Ltd ("we" or "us")</span></p>
                <p><span>We are responsible for the collection and proper management of any personal information you submit. We will keep your personal details secure and use the information you provide consistently with applicable privacy and data protection laws and the terms of this Privacy Policy.</span>
                </p>
                <strong>
                  <span>2. <span>What information do we collect?</span></span>
                </strong>
                <p><span>If you choose to make a booking through us we will collect details that you provide in relation to that booking. Information held is your name, email address, country of residence and telephone number (optional) and any message received on the enquiry form.</span>
                </p>
                <strong>
                  <span>3. <span>How do we use your informatio</span>n?</span>
                </strong>
                <p><span>The information you provide may be used as follows:</span></p>
                <ul>
                  <li><span>to enable us to provide you with a quotation for booking a holiday villa </span>
                  </li>
                  <li><span>to provide you with updates on events happening in Thailand&nbsp;and introduce new villas added to our website we think may be of interest to you.</span>
                  </li>
                  <li><span>to manage customer service queries</span></li>
                  <li><span>to help us to provide details or information you may have requested.</span></li>
                </ul>
                <strong>
                  <span>4. <span>Who will you share my information with?</span></span>
                </strong>
                <p><span>We do not share your information with any third party</span></p>
                <strong>
                  <span>5. <span>How do you manage my information?</span></span>
                </strong>
                <p><span>Your name and email address is used for updating you with news, information and events in Thailand together with the latest updates on our villa portfolio by email newsletter.</span>
                </p>
                <strong>
                  <span>6. <span>When can we contact you in the future?</span></span>
                </strong>
                <p><span>If you would like to change any of your preferences relating to the way in which we may use your information for direct marketing <a
                  href="https://www.thailandretreats.com/Contact/Home">contact</a> us by email.</span></p>
                <p>
                  <span>7. <span>How long will we hold your information for?</span></span>
                </p>
                <p><span>We hold your name and email address for continued marketing purposes by newsletter as subscribed when booking a villa. The newsletter can be unsubscribed at anytime.</span>
                </p>
                <strong>
                  <span>8. <span>How can you access and update your information?</span></span>
                </strong>
                <p><span>You have the right to request a copy of the information that we hold about you. If you would like a copy of some or all of your personal information, please email us with your request</span>
                </p>
                <p><span>We want to make sure that your personal information is accurate and up to date. You may ask us to correct or remove information you think is inaccurate.</span>
                </p>
                <strong>
                  <span>9. <span>Does the policy apply to linked websites?</span></span>
                </strong>
                <p><span>Our websites may contain links to other websites. This privacy policy only applies to this website so when you link to other websites you should read their own privacy policies.</span>
                </p>
                <strong>
                  <span>10. <span
                  >When do you monitor telephone calls?</span></span></strong>
                <p><span>We do not monitor telephone calls</span></p>
                <strong>
                  <span>11. <span>How do we update this privacy policy?</span></span>
                </strong>
                <p><span>We keep our privacy policy under regular review and we will place any updates on our webpage. This privacy policy was last updated in May 2018.</span>
                </p>
                <strong><span>12. <span>Who can I contact if I have queries about this privacy policy?</span></span>
                </strong>
                <p><span>Please <a href="#" onClick={App.showTawk}>contact us</a> if you have any questions about our privacy policy or information we hold about you.</span>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
