import React from 'react';
import { Form, FormFeedback, Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import styled from 'styled-components';
import './VillaModal.scss';
import _ from 'lodash'

const EnquiryResponse = styled.div`
  text-align: center;

  strong {
    display: block;
      margin-bottom: 10px;
  }
`

class EnquiryModal extends React.Component {
  state = {
    showValidation: false,
    delivered: false,
    name: '',
    email: '',
    phone: '',
    details: '',
  }
  
  getError(key) {
    const {name, email, details} = this.state
    
    switch (true) {
      case key === 'name' && (name === '' || name === undefined):
        return 'Name is required.'
      case key === 'name' && name.length < 3:
        return 'Name must be more than 2 characters.'
      case key === 'name' && name.length > 50:
        return 'Name must be less than 50 characters.'

      case key === 'email' && (email === '' || email === undefined):
        return 'Email is required.'
      case key === 'email' && email.length < 2:
        return 'Email must be more than 2 characters.'
      case key === 'email' && email.length > 50:
        return 'Email must be less than 50 characters.'

      case key === 'details' && (details === '' || details === undefined):
        return 'This field is required.'
      case key === 'details' && details.length < 11:
        return 'This field must be more than 10 characters.'
      case key === 'details' && details.length > 250:
        return 'This field must be less than 250 characters.'

      default: return undefined
    }
  }
  
  isInvalid(elem) {
    return this.state.showValidation === true && this.getError(elem) !== undefined
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }
  
  handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()
    
    if (!this.state.showValidation) {
      this.setState({showValidation: true})
    }
    
    const formValid = _.chain(['name', 'phone', 'email', 'details'])
      .map(k => this.getError(k) === undefined)
      .every()
      .value()

    if (formValid) {
      this.setState({delivered: true})

      window.Tawk_API.addEvent('offline-enquiry', {
        name: this.state.name,
        phone: this.state.phone,
        email: this.state.email,
        details: this.state.details,
      }, (error) => { });
    }
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          className="enquiry-modal"
        >
          <ModalHeader toggle={this.props.toggle}>
            <span>Make an Enquiry</span>
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col sm={12}>
                  {this.state.delivered ?
                    <EnquiryResponse>
                      <strong>Your Message has Departed.</strong>
                      <p>We look forward to helping you do the same. Thanks for your interest, we'll be in touch soon.</p>
                    </EnquiryResponse>
                  :
                  <Form onSubmit={this.handleSubmit.bind(this)}>
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <Input required invalid={this.isInvalid('name')} name="name" id="name" value={this.state.name} onChange={this.handleChange} />
                      <FormFeedback>{this.getError('name')}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="phone">Phone</Label>
                      <Input type="phone" invalid={this.isInvalid('phone')} name="phone" id="phone" value={this.state.phone} onChange={this.handleChange} />
                      <FormFeedback>{this.getError('phone')}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input required invalid={this.isInvalid('email')} type="email" name="email" id="email" value={this.state.email} onChange={this.handleChange} />
                      <FormFeedback>{this.getError('email')}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="details">How can we help?</Label>
                      <Input type="textarea" invalid={this.isInvalid('details')} name="details" id="details" value={this.state.details} onChange={this.handleChange} />
                      <FormFeedback>{this.getError('details')}</FormFeedback>
                    </FormGroup>
                    <Button color="primary">Make Enquiry</Button>
                  </Form>
                  }
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default EnquiryModal