import React, {Component} from 'react';
import styled from "styled-components";
import FacebookVideo from '../vid/facebook.mp4';
import ChatPerson from '../img/chat-person.svg'
import App from "../App";

import EnquiryButton from './EnquiryButton'

const Wrapper = styled.div`
  position: relative;
  height: 700px;
  background: #2d343a;
`

const ChatPersonImg = styled.img`
  width: 93px;
`

class Banner extends Component {
  state = {
    opacity: 1,
    contentTranslate: 0,
    videoTranslate: 0,
  }

  constructor(props) {
    super(props)
    this.wrapper = React.createRef()
  }

  componentDidMount() {
    const transparentAtScroll = 450;
    const contentTranslateMax = 100;

    // TODO: might be able to make window resizing smoother...
    window.onscroll = () => {
      let pos = Math.min(Math.max(0, document.documentElement.scrollTop / transparentAtScroll), 1)
      let opacity = pos * -1 + 1
      let contentTranslate = contentTranslateMax * pos
      let videoTranslate = document.documentElement.scrollTop
      this.setState({opacity, contentTranslate, videoTranslate})
    }
  }

  componentWillUnmount() {
    window.onscroll = undefined
  }

  // TODO: Translate placeholder image by same amount.
  // TODO: Darken the overlay slightly with opacity.

  render() {
    return (
      <Wrapper ref={this.wrapper}>
        <div className="headerContent"
             style={{opacity: this.state.opacity, transform: `translate(0, ${this.state.contentTranslate}px)`}}>
          <div className="headerContentInner">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center aos-init aos-animate" data-aos="fade-up">
                  <h1>Beautiful Thailand Villas</h1>
                  <p>Hand-picked villa rentals to suit any budget</p>
                  <EnquiryButton/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="headerBackground">
          <div id="videoOverlay"/>
          <video
            autoPlay
            loop
            muted
            playsInline
            id="videoBackground"
            style={{transform: `translate(-50%, calc(-50% + (${this.state.videoTranslate}px)))`}}>
            <source src={FacebookVideo} type="video/mp4"/>
          </video>
        </div>
      </Wrapper>
    );
  }
}

export default Banner
