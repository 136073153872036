import React, {Component} from 'react';
import Logo from '../img/logo.svg';
import Abta from '../img/abta.svg';
import Phone from '../img/phone.svg';
import ChatBubble from '../img/chat-bubble.svg'
import styled from 'styled-components';
import {Container} from "reactstrap";
import App from "../App";
import {Link} from "react-router-dom";

const LogoImg = styled.img`
  width: 200px;
  margin-right: 30px;
`

const AbtaImg = styled.img`
  width: 100px;
`

const ChatBubbleImg = styled.img`
  width: 16px;
  margin-right: 5px;
`

const PhoneImg = styled.img`
  width: 16px;
  margin-right: 5px;
`

class Header extends Component {
  render() {
    return (
      <header className="Header fixed-top navHeader">
        <Container>
          <div className="row navRow no-gutters align-items-center">
            <div className="col col-md-3 col-lg-auto" id="logoCol">
              <Link to="/">
                <LogoImg src={Logo} id="logoRect" alt=""/>
              </Link>
            </div>
            <div className="d-none d-sm-flex col-sm" id="abtaCol">
              <AbtaImg src={Abta} id="navAbta" alt=""/>
            </div>
            <div className="col-auto justify-content-center align-items-center">
              <div id="navPhone">
                <a href="tel:020 8144 0482" id="navPhoneMobile" className="">
                  <PhoneImg src={Phone} alt=""/>
                  <span>
                    <span>Call</span>
                    <span id="navPhoneDesktop" className="d-none d-md-inline">020 8144 0482</span>
                  </span>
                </a>
              </div>
            </div>
            <div className="col-auto justify-content-center align-items-center" id="navCTACol">
              <div id="navCTA">
                <a href="#" onClick={App.showTawk} className="navCTAMobile livechat d-flex" id="liveChatBtn">
                  <ChatBubbleImg src={ChatBubble} alt=""/>
                  <span className="d-none d-md-inline">Live Chat</span>
                  <span className="d-inline d-sm-inline d-md-none">Chat</span>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </header>
    );
  }
}

export default Header;
