import React, {Component} from 'react';
import Banner from "../components/Banner";
import VillaSearch from "../components/VillaSearch";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Banner/>
        <div id="main">
          <VillaSearch/>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
