import React, {Component} from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import routes from './routes'
import breakpoints from "./breakpoints";
import styled from 'styled-components'
import config from './config.json'

const Content = styled.div`
  margin-top: 94px;
  
  @media (max-width: ${breakpoints.md}px) {
    margin-top: 69px;
  }
`

class App extends Component {
  state = {
    config: {},
    tawkStatus: '',
  }

  static startTawk(tawkKey) {
    window.Tawk_API = {}
    window.Tawk_LoadStart = new Date()
    let s1 = document.createElement("script")
    let s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = `https://embed.tawk.to/${tawkKey}/default`;
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  }

  static showTawk(event) {
    if (window.Tawk_API.getStatus() === 'online') {
      if (event) event.preventDefault()
      window.Tawk_API.maximize();
    }
  }

  componentDidMount() {
      this.setState(
        {config: config},
        () => App.startTawk(this.state.config.tawkKey),
      )
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Header tawkStatus={this.state.tawkStatus}/>
          <Content>
            <Switch>
              {routes.map((route, k) => <Route {...route} key={k}/>)}
              <Redirect from="/*" to="/"/>
            </Switch>
          </Content>
          <Footer/>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
