import Home from "./pages/Home";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";

export default [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/terms-and-conditions",
    component: Terms,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
  },
];