import React, { Component } from 'react';
import Logo from '../img/logo.svg'
import { Col, Collapse, Container, Row } from "reactstrap";
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import breakpoints from "../breakpoints";

const FooterImg = styled.img`
  width: 300px;
  
  @media (max-width: ${breakpoints.md}px) {
    width: 280px;
    margin: 0 auto;
    display: block;
  }
`

const CopyrightText = styled.div`
  font-size: 12px;
  margin-top: 20px;

  @media (max-width: ${breakpoints.md}px) {
    text-align: center;
  }
`

const FooterNav = styled.ul`
  padding: 0;
  
  li {
    list-style: none;
    padding: 6px 0;
    
    a {
      color: #999;
    }
  }
  
  @media (max-width: ${breakpoints.md}px) {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
  }
`

class Footer extends Component {
  state = {
    isOpen: false,
  }

  collapse = () => {
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <footer className="Footer fixed-bottom">
        <div className="footerBtn btn btn-primary d-none d-md-inline-block"
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          {this.state.isOpen ? 'Hide' : 'Show'} Footer
        </div>
        <div className="footerBtn btn btn-primary d-md-none"
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          {this.state.isOpen ? '\u2193' : '\u2191'}
        </div>
        <Collapse isOpen={this.state.isOpen} id="footer">
          <div id="footerCTA">
            <Container>
              <Row>
                <Col>
                  <strong>Let our team take the hassle out of finding your villa</strong>
                </Col>
              </Row>
            </Container>
          </div>
          <div id="footerMeta">
            <Container>
              <Row>
                <Col sm="12" md="6">
                  <FooterImg id="logoFooter" src={Logo} alt="" />
                  <CopyrightText>&copy; 2018 Thailand Retreats. All Rights Reserved.</CopyrightText>
                </Col>
                <Col sm="12" md="6">
                  <FooterNav>
                    <li>
                      <Link to="/terms-and-conditions" onClick={this.collapse}>Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy" onClick={this.collapse}>Privacy Policy</Link>
                    </li>
                    <li>
                      <a href="//thailandretreats.com" rel="noopener noreferrer" target="_blank" onClick={this.collapse}>
                        Main Website
                      </a>
                    </li>
                  </FooterNav>
                </Col>
              </Row>
            </Container>
          </div>
        </Collapse>
      </footer>
    );
  }
}

export default Footer;
