import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import ChatPerson from '../img/chat-person.svg'
import ChatBubble from '../img/chat-bubble.svg'
import App from "../App";
import EnquiryModal from './EnquiryModal'

const Wrapper = styled.div`
  width: 330px;
  height: 93px;
  
  background: #2196F3;
  border-radius: 3px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  font-size: 0.9rem;

  padding: 0;
  padding-left: 110px;
  margin: 0 auto;
  
  display: flex;
  align-items: center;
  justify-content: center; position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .1), 0 2px 4px rgba(0, 0, 0, .1);
  transition: .3s;
  transform: translate3d(0, 0, 0);
  transition-timing-function: cubic-bezier(.25, .1, .2, 1);

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(top, #fff 0, #000 100%);
    background: linear-gradient(to bottom, #fff 0, #000 100%);
    z-index: -1;
    opacity: .1;
    box-sizing: inherit;
  }

  :hover {
    box-shadow: 0 6px 7px 0 rgba(0, 0, 0, .15), 0 0 5px 0 rgba(0, 0, 0, .1);
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-transition: .3s;
    transition: .3s;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }

  strong {
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
  }

  img.person {
    width: 113px;
    position: absolute;
    left: 0;
    opacity: 0.9;
  }

  img.chat {
    width: 80px;
    position: absolute;
    left: 40px;
    opacity: 0.9;
  }

  svg {
    fill: #fff;
    opacity: 0.9;
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
`

class ExpiryButton extends React.Component {
  state = {
    online: _.has(window, 'Tawk_API.getStatus') && window.Tawk_API.getStatus() === 'online',
    interval: undefined,
    isModalVisible: false,
  }

  componentDidMount() {
    const interval = setInterval(() => {
      const online = window.Tawk_API.getStatus() === 'online'
      if (online !== this.state.online) {
        this.setState({ online })
      }
    }, 1000)
    this.setState({interval})
  }

  componentWillUnmount() {
    clearInterval(this.state.interval)
  }
  
  handleClick(event) {
    if (this.state.online) {
      App.showTawk()
    } else {
      this.setState({isModalVisible: true})
    }
  }
  
  render() {
    return (
      <Wrapper onClick={this.handleClick.bind(this)} className={this.props.className}>
        {this.state.online ?
          <React.Fragment>
            <img className="person" src={ChatPerson} alt="" />
            <div>
              <strong>Live Chat</strong>
              <span>Our agents are experts</span>
            </div>
          </React.Fragment>
        :
          <React.Fragment>
            <img className="chat" src={ChatBubble} alt="" />
            <div>
              <strong>Enquire</strong>
              <span>Send Enquiry</span>
            </div>
          </React.Fragment>
        }
        <EnquiryModal isOpen={this.state.isModalVisible} toggle={() => this.setState({isModalVisible: false})}/>
      </Wrapper>
    )
  }
}

export default ExpiryButton