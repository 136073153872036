import React, {Component} from 'react';
import {Col, Container, Row} from "reactstrap";
import styled from 'styled-components'

const TermText = styled.div`
  // font-size: 0.9em;
  // color: #777;
`

class Terms extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="main">
          <Container>
            <Row>
              <Col>
                <h1>Terms and Conditions</h1>
                <TermText>
                  <strong>1. General</strong>
                  <p>Please note that each villa management has their own Terms and Conditions as set out in the villa
                    details of each individual villa and will override these and are to be taken as a guide only.</p>
                  <p>1.1 Thailand Retreats is the trading name of Thailand Retreats Limited, whose registered office in
                    St. Saviour, Jersey, Channel Islands.</p>
                  <p>1.2 Thailand Retreats is contractually bound to consumers to provide luxury accommodation on behalf
                    of owner and property managers whose properties are featured on the websites
                    www.ThailandRetreats.com</p>
                  <p>1.3&nbsp;Accuracy of Information.</p>
                  <p>Thailand Retreats relies on the information supplied to us by owners and property managers and have
                    been viewed accordingly at the time of accepting the villas for promotion and at regular intervals
                    thereafter.&nbsp;<br/> Although Thailand Retreats makes every effort to ensure that advertised
                    descriptions are correct, it does not own any of the properties and therefore cannot control or
                    prevent changes.&nbsp;<br/> Although changes to properties advertised are infrequent, Thailand
                    Retreats reserves the right to change the description or service of any property at any time, in
                    which case we will endeavour to notify you of all changes before commencement of the booking date,
                    if it is practical to do so.</p>
                  <strong>2. Bookings</strong>
                  <p>2.1 Deposit Payment<br/> An initial deposit of 15 -- 50% (depending on the property) of the
                    property rental must be paid to confirm a booking. Settlement can be made by direct bank transfer in
                    GBP, USD, or THB and on-line credit card payment with VISA, Mastercard or American Express. No
                    contract will exist between you and Thailand Retreats until receipt of payment, Should the deposit
                    payment not be received within 5 working days and we are unsuccessful in further contact Thailand
                    Retreats will presume that the reservation has been cancelled. Once a deposit has been received the
                    reservation will be confirmed and a receipt of payment sent.</p>
                  <p>2.2 Balance Payment<br/> Payment of the outstanding balance is due 75 &ndash; 45 days ( depending
                    on te property) before the arrival date and Thailand Retreats will send out a reminder close to the
                    due date. If the booking is made within this period before the arrival date, 100% of the cost of the
                    property rental shall be due at the time of booking.</p>
                  <p>2.3 Exclusions &amp; Additional Charges<br/> Rates do not include baggage handling, gratuities,
                    telephone, fax and telegram charges, car rental, food, soft drinks and/or liquor, personal items and
                    expenses, and any excessive cleaning required upon departure (such as upholstery or rug shampooing,
                    etc) or replacement of breakages. Fair wear and tear accepted. All the above charges are payable by
                    you locally. In some cases there may be a security deposit payable on arrival usually in any of the
                    main currencies. This will be returned to you on departure providing there is no damage to the
                    property. The renter agrees to pay the owner for the cost of repairing or replacing (solely at the
                    owner's discretion) any item of property, or contents at the Villa/Apartment, which is damaged due
                    to negligence or willful default of the renter during the renter's occupation, or which is missing.
                    The person in whose name the booking is made acts on behalf of all other persons named, and becomes
                    responsible to Thailand Retreats for all payments in respect of the booking.</p>
                  <p>2.4 Currency Fluctuations<br/> The base currency of the villa will be stated in the booking
                    details. Please note that the balance of the rental due in USD and paid in GBP will be invoiced
                    using the prevailing foreign exchange rate of the due date concerned.</p>
                  <strong>3. Cancellation Conditions</strong>
                  <p>If it becomes necessary to cancel all or any part of the booking, Thailand Retreats Ltd must be
                    notified in writing (email acceptable). The cancellation will take effect from the day the written
                    confirmation is received.</p>
                  <p>The following cancellation charges are standard charges and will be applicable depending on when
                    the notification of the cancellation is received in writing. Some villa owners have their own
                    individual Terms and Conditions and if different from our own will be sent to you during the booking
                    process or will be stated in the details of the villa.</p>
                  <p>30 days or more before arrival date:<br/> Deposit forfeited - but credited for 9 months from the
                    date of the cancellation confirmation. The credit can be used by the client or anyone on their
                    behalf for any reservation at the original villa, during any period.</p>
                  <p>Within 30 days of arrival date:<br/> All payments are forfeited unless the property can be
                    successfully rebooked for the dates cancelled.</p>
                  <p>Prime &amp; Peak season dates (Chines New Year, Easter &amp; Christmas and New Year
                    Period)<br/> For any cancellation after the deposit and/or balance payment, all payments are
                    forfeited and a full or partial refund is solely applicable in the event that another reservation is
                    secured covering partially or fully the initial and cancelled reservation.</p>
                  <p>3.1 Cancellations and/or Alterations by Thailand Retreats.<br/> Although it is unlikely that
                    Thailand Retreats will have to make any changes to confirmed arrangements, it is sometimes
                    unavoidable in which case we will advise you at the earliest possible date. If, for any reason
                    beyond our control, we are unable to provide you with the property booked due to the property being
                    damaged or rendered unusable Thailand Retreats together with the villa management will endeavour to
                    locate an alternative property for the period required or offer a full refundof the payment in the
                    currency made to the villa owner/property manager at the time of booking. Thailand Retreats shall
                    not be liable for any further obligations or claims by the client.</p>
                  <strong>4. Change of Booking</strong>
                  <p>Thailand Retreats will charge an administration fee of &pound;30 for any change to the booking
                    after a confirmation has been sent. All changes will be subject to approval by the owner/property
                    manager.</p>
                  <strong>5. Arrival &amp; Departure Times</strong>
                  <p>Guest should assume that Check-in time is after 3pm., and checkout time is prior to 11am.</p>
                  <strong>6. Registered Guests</strong>
                  <p>Only those persons stipulated on the booking form may reside at the villa as guests. Please notify
                    us as soon as possible of any changes. The number of people staying at the villa must not exceed the
                    maximum capacity indicated by you in the Thailand Retreats booking form, except in the case of
                    infants (under two years old), or unless specifically authorised. Should the owner or the local
                    representative find that the number of people staying at the property exceeds that on the booking
                    form, he may, at the absolute discretion of Thailand Retreats, ask the extra person or persons to
                    vacate the property forthwith. No camping is permitted in the grounds or gardens adjacent to the
                    villas.</p>
                  <strong>7. Insurance</strong>
                  <p>It is a condition of the booking that the entire party is covered by comprehensive travel insurance
                    (including cancellation, flight delays, loss and damage to baggage and other property) and health
                    insurance (including evacuation and repatriation coverage). Thailand Retreats is not responsible to
                    you and your party for any and all claims including any accidents related to the use of the property
                    facilities or locally procured third party services such as, watercraft, water sports, jeep or
                    motorbike rental etc. Thailand Retreats will not be responsible for any delay, additional expense or
                    inconvenience caused directly or indirectly by events outside Thailand Retreats' control such as
                    civil disturbances, fires, floods, severe weather, acts of God, Acts of Government etc.</p>
                  <strong>8. Complaints</strong>
                  <p>Once a property has been booked and paid for in full, Thailand Retreats will request flight arrival
                    and departure details to pass on to the property manager. Once these have been received we shall
                    provide you with the owner/property manager contact details should you have any travel related
                    problems.&nbsp;<br/> In the unlikely event of a problem with your rental property occurring, whilst
                    you are abroad, you must inform the villa owner or property manager so that the matter can be put
                    right. If the situation cannot be resolved to your satisfaction, at the time, you may contact
                    Thailand Retreats, so that Thailand Retreats is given the opportunity to try to assist. All claims
                    initially must be addressed directly to the property owner/manager and any compensation agreed
                    before guest departure.<br/> Thailand Retreats does not accept responsibility for the breakdown of
                    the supply of water, or electricity, internet connection or of swimming pool filtration systems,
                    though we will use our best endeavours to arrange for any such problems to be solved quickly.&nbsp;
                    <br/> If there are any problems during the rental period, which could not be resolved by dealing
                    directly with the local staff, contact Thailand Retreats immediately and we shall try to rectify the
                    situation.&nbsp;<br/> It is understood that infrastructure, local standards and conditions are often
                    of a less developed nature in a remote resort location than in more urban environments. Thailand
                    Retreats will do as much as can be reasonably expected to rectify any problems that may occur, but
                    cannot be held responsible for any situation that for some reason cannot be avoided.&nbsp;<br/> Any
                    complaints must be notified to Thailand Retreats within 24 hours of the cause of the complaint
                    occurring for us to act and contact the owner/property manager.&nbsp;<br/> If the Villa is vacated
                    before the end of the rental period without prior agreement with Thailand Retreats or the property
                    owner/manager, this may result in the loss of all right to any compensation.</p>
                  <strong>9. Conduct &amp; Behaviour</strong>
                  <p>The lead person who makes the booking is responsible for the correct and appropriate behaviour of
                    the guests staying at the property. Should any member of the party not behave in such a manner
                    Thailand Retreats, owner or property manager may at their absolute discretion ask the offending
                    guest or guests to vacate the property forthwith.</p>
                  <strong>10. Staff at the Properties</strong>
                  <p>The service of staff is included at some of the properties. Additional services such as those of
                    cooks and/or drivers can usually be sourced. Please give ample notice as soon as possible;
                    especially at peak holiday seasons and we shall try to assist. Thailand Retreats can assume no
                    liability for such contracted staff. The rates for these services will be provided in advance and
                    shall be paid by you as incurred.</p>
                  <strong>11. Valuables</strong>
                  <p>All of the villas have a safe and any valuables left at the property are left at one's own risk.
                    Neither Thailand Retreats or the owners or staff at the property are responsible for any loss or
                    damage to valuables or other property.</p>
                  <strong>12. Jurisdiction</strong>
                  <p>Any contract that is made will be with the acceptance by both parties of these Terms and
                    Conditions, which are governed by the States of Jersey law, and both parties will submit to the
                    exclusive jurisdiction of the States of Jersey courts.</p>
                  <strong>13. Agreement</strong>
                  <p>These Terms &amp; Conditions govern all bookings with Thailand Retreats Ltd. Any booking made or
                    order placed by you, whether through the Company's website or otherwise, shall be deemed an offer by
                    you to rent the relevant accommodation subject to these Terms &amp; Conditions. All bookings through
                    our Company and all matters arising from them are subject to the States of Jersey law and to the
                    exclusive jurisdiction of the courts of the States of Jersey.&nbsp;<br/> <br/> The person who makes
                    the booking accepts these conditions on behalf of all members of the party and is responsible for
                    all payment due from the party. Booking services with the company are only available to persons who
                    are at least 18 years old. By submitting a booking you warrant and confirm to us that you have read
                    these Terms &amp; Conditions and agree to comply with them.</p>
                </TermText>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Terms;
